/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "preflight",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#preflight",
    "aria-label": "preflight permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Preflight"), "\n", React.createElement(_components.p, null, "An opinionated set of base styles for xstyled projects."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.p, null, "Inspired from ", React.createElement(_components.a, {
    href: "https://tailwindcss.com/docs/preflight"
  }, "Tailwind's Preflight"), " and build on top of ", React.createElement(_components.a, {
    href: "https://github.com/sindresorhus/modern-normalize"
  }, "modern-normalize"), ", Preflight is a set of base styles for xstyled projects that are designed to smooth over cross-browser inconsistencies and make it easier for you to work within the constraints of your design system."), "\n", React.createElement(_components.p, null, "Preflight is available as a Global Style React Component, when mounted styles are automatically injected."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { Preflight } from '@xstyled/...'\n\nfunction App() {\n  return (\n    <>\n      <Preflight />\n      {/* ... */}\n    </>\n  )\n}\n")), "\n", React.createElement(_components.h2, {
    id: "default-margins-are-removed",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#default-margins-are-removed",
    "aria-label": "default margins are removed permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Default margins are removed"), "\n", React.createElement(_components.p, null, "Preflight removes all of the default margins from elements like headings, blockquotes, paragraphs, etc."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css"
  }, "blockquote,\ndl,\ndd,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\nhr,\nfigure,\np,\npre {\n  margin: 0;\n}\n")), "\n", React.createElement(_components.p, null, "This makes it harder to accidentally rely on margin values applied by the user-agent stylesheet that are not part of your spacing scale."), "\n", React.createElement(_components.h2, {
    id: "headings-are-unstyled",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#headings-are-unstyled",
    "aria-label": "headings are unstyled permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Headings are unstyled"), "\n", React.createElement(_components.p, null, "All heading elements are completely unstyled by default, and have the same font-size and font-weight as normal text."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css"
  }, "h1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  font-size: inherit;\n  font-weight: inherit;\n}\n")), "\n", React.createElement(_components.p, null, "The reason for this is two-fold:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "It helps you avoid accidentally deviating from your type scale"), ". By default, the browsers assigns sizes to headings that don't exist in Tailwind's default type scale, and aren't guaranteed to exist in your own type scale."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "In UI development, headings should often be visually de-emphasized"), ". Making headings unstyled by default means any styling you apply to headings happens consciously and deliberately."), "\n"), "\n", React.createElement(_components.p, null, "You can always add default header styles to your project by ", React.createElement(_components.a, {
    href: "/docs/adding-base-styles/"
  }, "adding your own base styles"), "."), "\n", React.createElement(_components.h2, {
    id: "lists-are-unstyled",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#lists-are-unstyled",
    "aria-label": "lists are unstyled permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Lists are unstyled"), "\n", React.createElement(_components.p, null, "Ordered and unordered lists are unstyled by default, with no bullets/numbers and no margin or padding."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css"
  }, "ol,\nul {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n}\n")), "\n", React.createElement(_components.p, null, "If you'd like to style a list, you can do so using the ", React.createElement(_components.a, {
    href: "/docs/list-style-type/"
  }, "listStyleType"), " and ", React.createElement(_components.a, {
    href: "/docs/list-style-position/"
  }, "listStylePosition"), " utilities:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.ul listStyleType=\"disc\" listStylePosition=\"inside\">\n  <li>One</li>\n  <li>Two</li>\n  <li>Three</li>\n</x.ul>\n")), "\n", React.createElement(_components.p, null, "You can always add default header styles to your project by ", React.createElement(_components.a, {
    href: "/docs/adding-base-styles/"
  }, "adding your own base styles"), "."), "\n", React.createElement(_components.h2, {
    id: "images-are-block-level",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#images-are-block-level",
    "aria-label": "images are block level permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Images are block-level"), "\n", React.createElement(_components.p, null, "Images and other replaced elements (like ", React.createElement(_components.code, null, "svg"), ", ", React.createElement(_components.code, null, "video"), ", ", React.createElement(_components.code, null, "canvas"), ", and others) are ", React.createElement(_components.code, null, "display: block"), " by default."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css"
  }, "img,\nsvg,\nvideo,\ncanvas,\naudio,\niframe,\nembed,\nobject {\n  display: block;\n  vertical-align: middle;\n}\n")), "\n", React.createElement(_components.p, null, "This helps to avoid unexpected alignment issues that you often run into using the browser default of ", React.createElement(_components.code, null, "display: inline"), "."), "\n", React.createElement(_components.p, null, "If you ever need to make one of these elements ", React.createElement(_components.code, null, "inline"), " instead of ", React.createElement(_components.code, null, "block"), ", simply use the ", React.createElement(_components.code, null, "display=\"inline\""), " utility:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.img display=\"inline\" src=\"...\" alt=\"...\" />\n")), "\n", React.createElement(_components.h2, {
    id: "border-styles-are-reset-globally",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#border-styles-are-reset-globally",
    "aria-label": "border styles are reset globally permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Border styles are reset globally"), "\n", React.createElement(_components.p, null, "In order to make it easy to add a border by simply adding the ", React.createElement(_components.code, null, "border"), " utility, xstyled overrides the default border styles for all elements with the following rules:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css"
  }, "*,\n::before,\n::after {\n  border-width: 0;\n  border-style: solid;\n  border-color: ${th.color('default-border-color', 'currentColor')};\n}\n")), "\n", React.createElement(_components.p, null, "Since the ", React.createElement(_components.code, null, "border"), " utiity only sets the ", React.createElement(_components.code, null, "border-width"), " and ", React.createElement(_components.code, null, "border-style"), " property, this reset ensures that adding that class always adds a solid 1px border using your configured default border color."), "\n", React.createElement(_components.p, null, "This can cause some unexpected results when integrating certain third-party libraries, like ", React.createElement(_components.a, {
    href: "https://github.com/tailwindlabs/tailwindcss/issues/484"
  }, "Google maps"), " for example."), "\n", React.createElement(_components.p, null, "When you run into situations like this, you can work around them by overriding the Preflight styles with your own custom CSS:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css"
  }, ".google-map * {\n  border-style: none;\n}\n")), "\n", React.createElement(_components.h2, {
    id: "buttons-have-a-default-outline",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#buttons-have-a-default-outline",
    "aria-label": "buttons have a default outline permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Buttons have a default outline"), "\n", React.createElement(_components.p, null, "To ensure that we provide accessible styles out of the box, we made sure\nthat buttons have a default outline. You can of course override this by\napplying ", React.createElement(_components.code, null, "ring"), " or similar utilities to your buttons."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css"
  }, "button:focus {\n  outline: 1px dotted;\n  outline: 5px auto -webkit-focus-ring-color;\n}\n")), "\n", React.createElement(_components.h2, {
    id: "buttons-have-pointer",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#buttons-have-pointer",
    "aria-label": "buttons have pointer permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Buttons have pointer"), "\n", React.createElement(_components.p, null, "To ensure accessibility, buttons have pointer."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css"
  }, "/* Role button pointer */\n[role='button'],\nbutton {\n  cursor: pointer;\n}\n")), "\n", React.createElement(_components.h2, {
    id: "default-ring-color",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#default-ring-color",
    "aria-label": "default ring color permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Default ring color"), "\n", React.createElement(_components.p, null, "A default ring color is added by Preflight to ensure that default ", React.createElement(_components.code, null, "ring"), " utility color is good."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css"
  }, "* {\n  --x-ring-color: ${th.color('default-ring-color', 'rgba(59,130,246,0.5)')};\n}\n")), "\n", React.createElement(_components.h2, {
    id: "extending-preflight",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#extending-preflight",
    "aria-label": "extending preflight permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Extending Preflight"), "\n", React.createElement(_components.p, null, "You can't extend or modify ", React.createElement(_components.code, null, "Preflight"), ", however you can additional ", React.createElement(_components.a, {
    href: "/docs/adding-base-styles"
  }, "add base styles"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
